import { navigate } from 'gatsby';
import { useAuth } from '@app/contexts/MainAppContext';
import React, { useEffect } from 'react';
import SpinnerDotPage from '@components/Spinners/SpinnerDotPage';

const Logout = () => {
  const { logoutUser } = useAuth();

  const handleAction = async () => {
    await logoutUser();
    navigate('/');
  };

  useEffect(() => {
    handleAction();
  }, []);

  return <SpinnerDotPage />;
};

export default Logout;
